
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { readProject } from '@/store/main/getters';
import { dispatchUninviteUser } from '@/store/main/actions';
import { getUserFullDisplayName } from '@/utils';
import Models from '@/views/main/project/Models.vue';
import Datasets from '@/views/main/project/Datasets.vue';
import FeedbackList from '@/views/main/project/FeedbackList.vue';

@Component({
	components: {
		Models, Datasets, FeedbackList
	}
})
export default class ProjectOverview extends Vue {

	@Prop({ required: true }) projectId!: number;
	@Prop({type: Boolean, required: true, default: false}) isProjectOwnerOrAdmin!: boolean;

	get project() {
		return readProject(this.$store)(this.projectId)
	}

	private getUserFullDisplayName = getUserFullDisplayName

	public async cancelUserInvitation(userId: string) {
		const confirm = await this.$dialog.confirm({
			text: `Are you sure you want to cancel invitation of user <strong>${userId}</strong>?`,
			title: 'Cancel user invitation'
    });
		if (this.project && confirm) {
			try {
				await dispatchUninviteUser(this.$store, {projectId: this.project.id, userId})
			}	catch (e) {
				console.error(e)
			}
		}
	}
}
