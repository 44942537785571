





























































































import {Component, Vue, Prop} from "vue-property-decorator";
import {api} from "@/api";
import {readToken} from "@/store/main/getters";
import {commitAddNotification} from '@/store/main/mutations';
import {IFeedbackDisplay} from "@/interfaces";
import Inspector from "./Inspector.vue";
import MessageReply from "@/components/MessageReply.vue";

@Component({
  components: {Inspector, MessageReply}
})
export default class FeedbackDetail extends Vue {
  @Prop({required: true}) id!: number;

  data: IFeedbackDisplay | null = null;
  replyPlaceholder = ""

  async mounted() {
    await this.reloadFeedback()
  }

  async reloadFeedback() {
    try {
      const response = await api.getFeedback(readToken(this.$store), this.id);
      this.data = response.data
    } catch (error) {
      commitAddNotification(this.$store, {content: error.response.data.detail, color: 'error'});
    }
  }

  resetInput() {
    if (this.data) this.data.user_data = {...this.data?.original_data}
  }

  get firstLevelReplies() {
    return !this.data ? [] : this.data.replies.filter(r => !r.reply_to_reply)
  }

  get secondLevelReplies() {
    return (replyId) => !this.data ? [] : this.data.replies.filter(r => r.reply_to_reply == replyId)
  }

  async doSendReply(content: string, replyToId: number | null = null) {
    try {
      await api.replyToFeedback(readToken(this.$store), this.id, content, replyToId)
      await this.reloadFeedback()
    } catch (error) {
      commitAddNotification(this.$store, {content: error.response.statusText, color: 'error'});
    }
  }
}
