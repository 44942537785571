





















































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IProjectFile } from "@/interfaces";
import OverlayLoader from "@/components/OverlayLoader.vue";
import { readToken } from "@/store/main/getters";
import { commitAddNotification } from "@/store/main/mutations";
import { api } from "@/api";

@Component({
  components: { OverlayLoader },
})
export default class InspectorLauncher extends Vue {
  @Prop({ required: true }) projectId!: number;
  @Prop({ required: true }) modelId!: number;
  loading = false;
  step = 1;
  datasets: IProjectFile[] = [];
  datasetSelected: number | null = null;
  targetFeature: string | null = null;
  datasetFeatures: string[] = [];
  errorLoadingFeatures: string | null = null;

  public async mounted() {
    this.loadDatasets();
  }

  @Emit("cancel")
  public reset() {
    this.step = 1;
    this.datasetSelected = null;
    this.datasetFeatures = [];
    this.targetFeature = null;
    this.errorLoadingFeatures = null;
  }

  public async loadDatasets() {
    this.loading = true;
    const response = await api.getProjectDatasets(
      readToken(this.$store),
      this.projectId
    );
    this.datasets = response.data.sort((a, b) =>
      new Date(a.creation_date) < new Date(b.creation_date) ? 1 : -1
    );
    this.loading = false;
  }

  public async loadDatasetFeatures() {
    if (this.datasetSelected) {
      try {
        this.loading = true;
        const response = await api.peakDataFile(
          readToken(this.$store),
          this.datasetSelected
        );
        const headers = JSON.parse(response.data)["schema"]["fields"];
        this.datasetFeatures = headers
          .map((e) => e["name"].trim())
          .filter((e) => e != "index");
      } catch (error) {
        commitAddNotification(this.$store, {
          content: error.response.data.detail,
          color: "error",
        });
        this.errorLoadingFeatures = error.response.data.detail;
      } finally {
        this.loading = false;
      }
    }
  }

  public launchInspector() {
    const query = {
      model: this.modelId.toString(),
			dataset: this.datasetSelected?.toString(),
			target: this.targetFeature || undefined,
		}
    this.$router.push({ name: 'project-inspector', query })
    this.reset();
  }
}
