





















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { api } from '@/api';
import { dialogDownloadFile, performApiActionWithNotif } from '@/api-commons';
import { readToken } from "@/store/main/getters";
import { formatSizeForDisplay } from '@/utils';
import { commitAddNotification } from '@/store/main/mutations';
import { IProjectFile } from "@/interfaces";

@Component
export default class Datasets extends Vue {
  @Prop({type: Number, required: true}) projectId!: number;
	@Prop({type: Boolean, default: false}) isProjectOwnerOrAdmin!: boolean;

  public files: IProjectFile[] = [];
  public fileData = null;
  public lastVisitedFileId;
  public filePreviewHeader: string[] = [];
  public filePreviewData: any[] = [];

  activated() {
		this.loadDatasets()
	}

	private async loadDatasets() {
		const response = await api.getProjectDatasets(readToken(this.$store), this.projectId)
    this.files = response.data.sort((a, b) => new Date(a.creation_date) < new Date(b.creation_date) ? 1 : -1);
	}
 
  public async upload_data() {
    performApiActionWithNotif(this.$store, 
      () => api.uploadDataFile(readToken(this.$store), this.projectId, this.fileData),
      () => {
        this.loadDatasets()
        this.fileData = null;
      })
  }

  public formatSizeForDisplay = (size: number) => formatSizeForDisplay(size);

  public async deleteDataFile(id: number, fileName: string) {
    if (await this.$dialog.confirm({
      text: `Are you sure you want to delete dataset <strong>${fileName}</strong>?`,
      title: 'Delete dataset'
    })) {
      performApiActionWithNotif(this.$store,
          () => api.deleteDatasetFile(readToken(this.$store), id),
          this.loadDatasets)
    }
	}

  public async downloadDataFile(id: number, fileName: string) {
    try {
      const response = await api.downloadDataFile(readToken(this.$store), id)
			dialogDownloadFile(response, fileName);
		} catch (error) {
      commitAddNotification(this.$store, { content: error.response.statusText, color: 'error' });
		}
	}

  public async peakDataFile(id: number) {
    if (this.lastVisitedFileId != id) { 
      this.lastVisitedFileId = id; // this is a trick to avoid recalling the api every time one panel is opened/closed 
      try {
        const response = await api.peakDataFile(readToken(this.$store), id)
        const headers = JSON.parse(response.data)['schema']['fields']
        this.filePreviewHeader = headers.map(e => {
          return {text: e['name'].trim(), value: e['name'], sortable: false,}
        });
        this.filePreviewData = JSON.parse(response.data)['data']
      } catch (error) {
        commitAddNotification(this.$store, { content: error.response.statusText, color: 'error' });
        this.filePreviewHeader = [];
        this.filePreviewData = [];
      }
    }
  }
}
