









































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IProjetFileModel } from '@/interfaces';
import { api } from '@/api';
import { dialogDownloadFile, performApiActionWithNotif } from '@/api-commons';
import { readToken } from '@/store/main/getters';
import { formatSizeForDisplay } from '@/utils';
import { commitAddNotification } from '@/store/main/mutations';
import InspectorLauncher from './InspectorLauncher.vue';

@Component({
  components: { InspectorLauncher }
})
export default class Models extends Vue {
	@Prop({type: Number, required: true}) projectId!: number;
	@Prop({type: Boolean, required: true, default: false}) isProjectOwnerOrAdmin!: boolean;

	models: IProjetFileModel[] = [];
  showInspectDialog = false;
  modelToInspect: number | null = null;

	activated() {
		this.loadModelPickles()
	}

	private async loadModelPickles() {
		const response = await api.getProjectModels(readToken(this.$store), this.projectId)
    this.models = response.data.sort((a, b) => new Date(a.creation_date) < new Date(b.creation_date) ? 1 : -1);
	}

	public formatSizeForDisplay = (size: number) => formatSizeForDisplay(size);

	public async deleteModelPickle(id: number, fileName: string) {
    if (await this.$dialog.confirm({
      text: `Are you sure you want to delete model <strong>${fileName}</strong>?`,
      title: 'Delete model'
    })) {
      performApiActionWithNotif(this.$store,
          () => api.deleteDatasetFile(readToken(this.$store), id),
          this.loadModelPickles)
    }
  }

	public async downloadModelPickle(id: number, fileName: string) {
		try {
			const response = await api.downloadModelFile(readToken(this.$store), id)
			dialogDownloadFile(response, fileName);
		} catch (error) {
			commitAddNotification(this.$store, { content: error.response.statusText, color: 'error' });
		}
	}

  public cancelLaunchInspector() {
    this.showInspectDialog = false;
  }

}
